<template>
  <b-card
    no-body
    :class="{ open: visible }"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      :class="{ collapsed: !visible }"
      class="p-0 align-items-stretch justify-content-start"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="isActions ? updateVisible(!visible) : addItem()"
    >
      <slot name="header">
        <div
          class="px-1 py-75 bg-primary align-content-center"
          style="border-top-left-radius: 0.375rem"
          :style="{ borderBottomLeftRadius: !visible ? '0.375rem' : 0 }"
        >
          <feather-icon :icon="lefticon" size="16" class="text-white" />
        </div>
        <div
          class="px-1 py-75 flex-grow-1 d-flex align-items-center justify-content-between"
        >
          <div>
            <p class="lead collapse-title mb-0">{{ title }}</p>
            <small>{{ subtitle }}</small>
          </div>
          <div v-if="isActions">
            <feather-icon
              icon="TrashIcon"
              size="16"
              @click.stop="removeItem()"
            />
          </div>
        </div>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body class="p-0 d-flex">
        <div
          class="py-75 bg-primary"
          style="flex: 0 0 3.15rem; border-bottom-left-radius: 0.375rem"
        ></div>
        <div class="px-1 pb-75 pt-0 flex-grow-1">
          <slot />
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCollapse } from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "Required",
    },
    lefticon: {
      type: String,
      default: "MessageSquareIcon",
    },
    isActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: "",
      openOnHover: this.$parent.hover,
    };
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null;
    },
  },
  created() {
    this.collapseItemID = uuidv4();
    this.visible = this.isVisible;
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val;
      this.$emit("visible", val);
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true);
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false);
    },
    addItem() {
      this.$emit("add-item");
    },
    removeItem() {
      this.$emit("remove-item");
    },
  },
};
</script>
<style scoped>
.collapse-icon [data-toggle="collapse"]:after {
  display: none !important;
}
</style>
