<template>
  <div>
    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Topics</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12">
            <b-form-group
              label="Subject"
              invalid-feedback="Subject is required"
              ref="topic_sub"
            >
              <v-select
                ref="topic_sub"
                v-model="topicObj.subjectID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="subjects"
                :clearable="false"
                label="subject"
                :reduce="(val) => val.id"
                placeholder="Select subject"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Topic"
              invalid-feedback="Topic is required."
              ref="topic_name"
            >
              <b-form-input
                id="top_name"
                placeholder="Enter topic name"
                ref="topic_name"
                v-model="topicObj.topic"
                @focusout="CheckTName()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-button
              variant="success"
              class=""
              @click="AddTopic()"
              :disabled="savingTopic"
              block
            >
              <b-spinner v-if="savingTopic" small type="grow" />
              <feather-icon v-else :icon="buttonIcon" />
              <span v-if="!savingTopic" class="ml-1">Save</span>
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          show-empty
          :items="topics"
          :fields="topFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editTop(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="DeleteTop(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Chapters</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12">
            <b-form-group
              label="Subject"
              invalid-feedback="Subject is required"
              ref="ch_sub"
            >
              <v-select
                ref="ch_sub"
                v-model="chapObj.subjectID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="subjects"
                :clearable="false"
                label="subject"
                :reduce="(val) => val.id"
                placeholder="Select subject"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Chapter"
              invalid-feedback="Chapter is required."
              ref="chapter"
            >
              <b-form-input
                id="chp"
                placeholder="Enter chapter name"
                ref="chapter"
                v-model="chapObj.chapter"
                @focusout="checkChap()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-button
              variant="success"
              class=""
              @click="AddChap()"
              :disabled="chapLoading"
              block
            >
              <b-spinner v-if="chapLoading" small type="grow" />
              <feather-icon v-else :icon="buttonIcon" />
              <span v-if="!chapLoading" class="ml-1">Save</span>
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          show-empty
          :items="chapters"
          :fields="chFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editChap(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteChap(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility4"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Quiz Type</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility4 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-baseline">
          <b-col md="10">
            <b-form-group
              label="Type"
              invalid-feedback="Type is required."
              ref="q_type"
            >
              <b-form-input
                placeholder="Enter type name"
                ref="q_type"
                v-model="typeObj.questionType"
                @focusout="checkType()"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="AddType()"
              :disabled="typeLoading"
            >
              <b-spinner v-if="typeLoading" small type="grow" />
              <feather-icon v-else :icon="buttonIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          show-empty
          :items="quesTypes"
          :fields="typeFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editType(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteType(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Levels</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-baseline">
          <b-col md="10">
            <b-form-group
              label="Level Name"
              invalid-feedback="Level name is required."
              ref="cat_name"
            >
              <b-form-input
                placeholder="Enter level name"
                name="classField"
                ref="cat_name"
                v-model="catObj.category"
                @focusout="CheckCatName()"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="AddCategory()"
              :disabled="savingCat"
            >
              <b-spinner v-if="savingCat" small type="grow" />
              <feather-icon v-else :icon="buttonIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          show-empty
          :items="categories"
          :fields="catFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editCat(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="DeleteCat(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-row align-h="center">
      <b-col xl="8" lg="10">
        <b-tabs
          align="center"
          nav-class="bg-white px-1 pt-50 mb-2 box-shadow-1"
        >
          <!-- content-class="text-dark" -->
          <b-tab>
            <template #title>
              <feather-icon icon="InfoIcon" size="18" />
              <span class="font-medium-1">Info</span>
            </template>

            <b-card>
              <b-row>
                <b-col md="6" cols="12">
                  <b-form-group invalid-feedback="Level is required" ref="diff">
                    <div class="">
                      <label class="bv-no-focus-ring col-form-label pt-0"
                        >Level</label
                      >
                      <feather-icon
                        class="cursor-pointer ml-1"
                        icon="EditIcon"
                        size="16"
                        v-b-tooltip.hover.top
                        title="Manage Level"
                        @click="openCat()"
                      />
                    </div>
                    <v-select
                      multiple
                      :closeOnSelect="false"
                      v-model="infoObj.catIDs"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="categories"
                      :reduce="(opt) => opt.id"
                      :clearable="false"
                      ref="diff"
                      label="category"
                      placeholder="Select level"
                      @input="checkLevel()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6" cols="12">
                  <b-form-group
                    invalid-feedback="Type is required"
                    ref="ques_type"
                  >
                    <div class="">
                      <label class="bv-no-focus-ring col-form-label pt-0"
                        >Type</label
                      >
                      <feather-icon
                        class="cursor-pointer ml-1"
                        icon="EditIcon"
                        size="16"
                        v-b-tooltip.hover.top
                        title="Manage Type"
                        @click="openType()"
                      />
                    </div>
                    <v-select
                      v-model="infoObj.questionTypeID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="quesTypes"
                      :reduce="(opt) => opt.id"
                      :clearable="false"
                      ref="ques_type"
                      label="questionType"
                      placeholder="Select type"
                      @input="checkTypeID()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6" cols="12">
                  <b-form-group
                    label="Class"
                    invalid-feedback="Class is required"
                    ref="class"
                  >
                    <v-select
                      ref="class"
                      v-model="infoObj.classID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="classes"
                      :clearable="false"
                      label="name"
                      :reduce="(val) => val.id"
                      placeholder="Select class"
                      @input="checkClsID()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6" cols="12">
                  <b-form-group
                    label="Subject"
                    invalid-feedback="Subject is required"
                    ref="subject"
                  >
                    <v-select
                      ref="subject"
                      v-model="infoObj.subjectID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="subjects"
                      :clearable="false"
                      label="subject"
                      :reduce="(val) => val.id"
                      placeholder="Select subject"
                      @input="checkSubID()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6" cols="12">
                  <b-form-group
                    invalid-feedback="Chapter is required"
                    ref="ques_chp"
                  >
                    <div class="">
                      <label class="bv-no-focus-ring col-form-label pt-0"
                        >Chapter</label
                      >
                      <feather-icon
                        class="cursor-pointer ml-1"
                        icon="EditIcon"
                        size="16"
                        v-b-tooltip.hover.top
                        title="Manage Chapter"
                        @click="openChap()"
                      />
                    </div>
                    <v-select
                      v-model="infoObj.chapterID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="chapters"
                      :reduce="(opt) => opt.id"
                      label="chapter"
                      :clearable="false"
                      ref="ques_chp"
                      placeholder="Select chapter"
                      @input="checkChID()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6" cols="12">
                  <b-form-group
                    invalid-feedback="Topic is required"
                    ref="topic"
                  >
                    <div class="">
                      <label class="bv-no-focus-ring col-form-label pt-0"
                        >Topic</label
                      >
                      <feather-icon
                        class="cursor-pointer ml-1"
                        icon="EditIcon"
                        size="16"
                        v-b-tooltip.hover.top
                        title="Manage Topic"
                        @click="openTop()"
                      />
                    </div>
                    <v-select
                      ref="topic"
                      v-model="infoObj.topicID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="topics"
                      :reduce="(val) => val.id"
                      label="topic"
                      :clearable="false"
                      placeholder="Select topic"
                      @input="checkTopID()"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12" v-if="false">
                  <b-form-group
                    label="Image"
                    invalid-feedback="Image is required"
                    required
                    ref="docs"
                  >
                    <!-- https://cdn.cloudious.net/file-1678189226453-406060527.png -->
                    <div class="d-flex justify-content-center">
                      <b-spinner
                        v-if="coverLoading == true"
                        type="grow"
                        label="Loading..."
                        variant="primary"
                        class="m-5"
                      />

                      <!-- d-flex align-items-center -->
                      <div
                        v-else-if="coverLoading == 'loaded'"
                        class=""
                        style="width: 100%"
                      >
                        <b-img
                          ref="previewEl"
                          :src="myObj.question.media"
                          @click="$refs.coverImg.click()"
                          style="
                            border-radius: 10px;
                            width: 90%;
                            height: 300px;
                            object-fit: contain;
                          "
                        />

                        <feather-icon
                          icon="Trash2Icon"
                          size="22"
                          class="text-danger cursor-pointer ml-1"
                          @click="deleteCover()"
                        />
                      </div>
                      <b-img
                        v-else
                        ref="previewEl"
                        src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                        @click="$refs.coverImg.click()"
                        style="border-radius: 10px"
                        height="120px"
                        width="120px"
                      />
                      <input
                        type="file"
                        id="coverImg"
                        hidden
                        ref="coverImg"
                        @change="selectCover()"
                        accept="image/*"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
          <b-tab active>
            <template #title>
              <feather-icon icon="DatabaseIcon" size="18" />
              <span class="font-medium-1">Questions</span>
            </template>

            <app-collapse accordion type="margin">
              <app-collapse-item
                v-for="(item, ind) in quesData"
                :key="ind"
                ref="ques"
                :title="
                  item.question.question
                    ? item.question.question
                    : 'Question ' + (ind + 1)
                "
                isActions
                @remove-item="removeValue(item, ind)"
              >
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label="Question *"
                      invalid-feedback="Question is required"
                    >
                      <b-form-input
                        placeholder="Enter your question here"
                        v-model="item.question.question"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      v-if="item.question.addExp"
                      invalid-feedback="Explanation is required"
                    >
                      <div class="d-flex justify-content-between">
                        <label class="bv-no-focus-ring col-form-label pt-0"
                          >Answer Explanation</label
                        >
                        <feather-icon
                          icon="TrashIcon"
                          size="16"
                          class="cursor-pointer"
                          @click="
                            item.question.explanation = '';
                            item.question.addExp = false;
                          "
                        />
                      </div>
                      <b-form-textarea
                        placeholder="Enter answer explanation here"
                        v-model="item.question.explanation"
                        rows="2"
                        max-rows="5"
                      ></b-form-textarea>
                    </b-form-group>

                    <b-button
                      v-else
                      variant="flat-dark"
                      size="sm"
                      @click="item.question.addExp = true"
                    >
                      <feather-icon icon="PlusIcon" />
                      Answer Explanation
                    </b-button>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Options *"
                      invalid-feedback="Options is required"
                    >
                      <draggable
                        v-model="item.options"
                        handle=".draggable-task-handle"
                        tag="ul"
                        class="p-0"
                      >
                        <li
                          v-for="(opt, optInd) in item.options"
                          :key="optInd"
                          class="list-unstyled d-flex align-items-center mb-1"
                        >
                          <feather-icon
                            icon="GridIcon"
                            size="16"
                            class="draggable-task-handle cursor-move mr-1"
                          />
                          <b-form-input
                            :placeholder="'Option ' + (optInd + 1)"
                            v-model="opt.option"
                          />
                          <feather-icon
                            v-if="item.options.length > 2"
                            icon="TrashIcon"
                            size="16"
                            class="cursor-pointer ml-50"
                            @click="removeOption(item.options, optInd)"
                          />
                          <feather-icon
                            icon="PlusIcon"
                            size="16"
                            class="cursor-pointer ml-50"
                            @click="addOption(item)"
                          />
                        </li>
                      </draggable>
                    </b-form-group>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <app-collapse-item
                title="Add Question"
                subtitle="Add a new question to your quiz"
                lefticon="PlusIcon"
                @add-item="AddValue"
              >
              </app-collapse-item>
            </app-collapse>
          </b-tab>
        </b-tabs>

        <b-card v-if="false">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Explanation"
                invalid-feedback="Explanation is required"
                ref="answer"
              >
                <b-form-textarea
                  ref="answer"
                  placeholder="Enter answer explanation here"
                  v-model="myObj.question.explanation"
                  rows="3"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Explanation Media"
                invalid-feedback="Explanation Media are required"
                required
                ref="exp"
              >
                <div class="d-flex justify-content-center">
                  <b-spinner
                    v-if="expLoading == true"
                    type="grow"
                    label="Loading..."
                    variant="primary"
                    class="m-5"
                  />

                  <!-- d-flex align-items-center -->
                  <div
                    v-else-if="expLoading == 'loaded'"
                    class=""
                    style="width: 100%"
                  >
                    <b-img
                      ref="previewEl"
                      :src="myObj.question.explanationMedia"
                      @click="$refs.expImg.click()"
                      style="
                        border-radius: 10px;
                        width: 90%;
                        height: 300px;
                        object-fit: contain;
                      "
                    />

                    <feather-icon
                      icon="Trash2Icon"
                      size="22"
                      class="text-danger cursor-pointer ml-1"
                      @click="deleteExpl()"
                    />
                  </div>
                  <b-img
                    v-else
                    ref="previewEl"
                    src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                    @click="$refs.expImg.click()"
                    style="border-radius: 10px"
                    height="120px"
                    width="120px"
                  />
                  <input
                    type="file"
                    id="expImg"
                    hidden
                    ref="expImg"
                    @change="selectExpl()"
                    accept="image/*"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-row v-if="false" align-h="center" class="mt-1">
          <b-col md="8">
            <b-button
              block
              variant="success"
              :disabled="request"
              @click="save()"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BCardTitle,
  BTabs,
  BTab,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import draggable from "vuedraggable";

// original components are copied here and then UI is customized
import AppCollapse from "./question-cards/AppCollapse.vue";
import AppCollapseItem from "./question-cards/AppCollapseItem.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BCardTitle,
    BTabs,
    BTab,
    BCardText,
    BSidebar,
    BInputGroupPrepend,
    BFormTextarea,
    BInputGroup,
    AppCollapse,
    AppCollapseItem,
    draggable,
    vSelect,
    flatPickr,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  async created() {
    //   let right = {};
    //   this.$store.state.menu.forEach((el) => {
    //     el.children.forEach((ch) => {
    //       right[ch.route] = {
    //         view: ch.view,
    //         add: ch.add,
    //         edit: ch.edit,
    //         delete: ch.delete,
    //       };
    //     });
    //   });
    //   // console.log(right);
    //   this.$store.commit("setRights", right);
    //   if (!this.$store.state.rights[this.$route.name]) {
    //     // console.log(this.$store.state.rights[this.$route.name]);
    //     this.$router.replace({
    //       name: "misc-not-authorized",
    //     });
    //   } else {
    //     this.rights = this.$store.state.rights[this.$route.name];
    //     this.LoadData();
    //   }
    if (this.$route.params.id > 0) {
      this.loadQues();
    }
    this.loadCat();
    this.loadTypes();
    this.loadChaps();
    this.loadTopic();
    this.LoadData();
  },
  computed: {
    filters: function () {
      return this.items.filter((pro) => {
        return pro.subject.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      checkList: [
        "Marzipan I love I love. Soufflé donut I love gummi bears powder. Candy danish biscuit.",
        "Halvah bonbon bonbon brownie sugar plum. Halvah I love cupcake I love.",
        "Cake muffin icing topping wafer topping gummi bears apple pie.",
        "Cotton candy gummi bears bear claw cake brownie jelly-o lemon drops croissant sweet roll.",
      ],
      rights: {},
      request: false,
      buttonIcon: "PlusIcon",
      dataLoading: false,
      visibility: false,
      searchQuery: "",
      classes: [],
      subjects: [],
      infoObj: {
        classID: 0,
        subjectID: 0,
        topicID: 0,
        catIDs: [],
        questionTypeID: 0,
        chapterID: 0,
      },
      quesData: [
        {
          question: {
            id: 0,
            campusID: this.$store.state.userData.cId,
            question: "",
            media: "",
            addExp: false,
            explanation: "",
            explanationMedia: "",
          },
          options: [
            {
              id: 0,
              option: "",
              isCorrect: false,
              questionID: 0,
              campusID: this.$store.state.userData.cId,
            },
            {
              id: 0,
              option: "",
              isCorrect: false,
              questionID: 0,
              campusID: this.$store.state.userData.cId,
            },
          ],
        },
      ],
      myObj: {
        question: {
          id: 0,
          campusID: this.$store.state.userData.cId,
          question: "",
          media: "",
          anserIndex: 0,
          explanation: "",
          explanationMedia: "",
        },
        options: [],
        classID: 0,
        subjectID: 0,
        topicID: 0,
        catIDs: [],
        questionTypeID: 0,
        chapterID: 0,
      },
      typeObj: {
        id: 0,
        questionType: "",
        campusID: this.$store.state.userData.cId,
      },
      quesTypes: [],
      visibility4: false,
      typeLoading: false,
      typeFields: [
        { label: "type", key: "questionType" },
        { key: "actions", label: "actions" },
      ],
      chapObj: {
        id: 0,
        chapter: "",
        subjectID: 0,
        campusID: this.$store.state.userData.cId,
      },
      chapters: [],
      visibility3: false,
      chapLoading: false,
      chFields: [
        { label: "chapter", key: "chapter" },
        { key: "actions", label: "actions" },
      ],
      categories: [],
      options: [
        {
          id: 0,
          option: "",
          isCorrect: false,
          questionID: 0,
          campusID: this.$store.state.userData.cId,
        },
      ],
      topics: [],
      topicObj: {
        id: 0,
        topic: "",
        subjectID: 0,
        campusID: this.$store.state.userData.cId,
      },
      savingTopic: false,
      topFields: [
        { label: "topic", key: "topic" },
        { key: "actions", label: "actions" },
      ],
      coverLoading: false,
      expLoading: false,
      catObj: {
        id: 0,
        category: "",
        campusID: this.$store.state.userData.cId,
      },
      visibility2: false,
      savingCat: false,
      catFields: [
        { label: "level", key: "category" },
        { key: "actions", label: "actions" },
      ],
    };
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    CheckCatName() {
      var elem = this.$refs["cat_name"];
      if (this.catObj.category.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async AddCategory() {
      this.CheckCatName();
      if (this.CheckCatName() == true) {
        if (this.catObj.id == 0) {
          //Add
          this.savingCat = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "QuizCategory?db=" +
              this.$store.state.userData.db,
            body: this.catObj,
            message: "Quiz Level added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.savingCat = false;
          if (status) {
            this.loadCat();
            this.catObj = {
              id: 0,
              category: "",
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        } else {
          //Edit
          this.savingCat = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "QuizCategory/" +
              this.catObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.catObj,
            message: "Quiz Level updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.savingCat = false;
          if (status) {
            this.loadCat();
            this.catObj = {
              id: 0,
              category: "",
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        }
      }
    },
    editCat(item) {
      this.catObj = { ...item };
      this.buttonIcon = "Edit2Icon";
      var elem = this.$refs["cat_name"];
      elem.state = undefined;
    },
    openCat() {
      this.catObj = {
        id: 0,
        category: "",
        campusID: this.$store.state.userData.cId,
      };

      this.visibility2 = true;
      this.buttonIcon = "PlusIcon";

      var elem = this.$refs["cat_name"];
      elem.state = undefined;
    },
    async DeleteCat(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "QuizCategory/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Quiz Level removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadCat();
      }
    },

    CheckTName() {
      var elem = this.$refs["topic_name"];
      if (this.topicObj.topic.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTSub() {
      var elem = this.$refs["topic_sub"];
      if (this.topicObj.subjectID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async AddTopic() {
      this.CheckTName();
      this.CheckTSub();
      if (this.CheckTName() == true && this.CheckTSub() == true) {
        if (this.topicObj.id == 0) {
          //Add
          this.savingTopic = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "QuizTopic?db=" +
              this.$store.state.userData.db,
            body: this.topicObj,
            message: "Quiz Topic added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.savingTopic = false;
          if (status) {
            this.loadTopic();
            this.topicObj = {
              id: 0,
              topic: this.topicObj.topic,
              subjectID: this.topicObj.subjectID,
              campusID: this.$store.state.userData.cId,
            };

            this.buttonIcon = "PlusIcon";
          }
        } else {
          //Edit
          this.savingTopic = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "QuizTopic/" +
              this.topicObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.topicObj,
            message: "Quiz Topic updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.savingTopic = false;
          if (status) {
            this.loadTopic();
            this.topicObj = {
              id: 0,
              topic: "",
              subjectID: 0,
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        }
      }
    },
    editTop(item) {
      this.topicObj = { ...item };
      this.buttonIcon = "Edit2Icon";
      var elem = this.$refs["topic_name"];
      elem.state = undefined;
      var elem = this.$refs["topic_sub"];
      elem.state = undefined;
    },
    openTop() {
      this.topicObj = {
        id: 0,
        topic: "",
        subjectID: this.myObj.subjectID,
        campusID: this.$store.state.userData.cId,
      };

      this.visibility = true;
      this.buttonIcon = "PlusIcon";

      var elem = this.$refs["topic_name"];
      elem.state = undefined;
      var elem = this.$refs["topic_sub"];
      elem.state = undefined;

      if (this.topicObj.subjectID > 0) {
        setTimeout(() => {
          let e = document.getElementById("top_name");
          e.focus();
        }, 200);
      }
    },
    async DeleteTop(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "QuizTopic/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Quiz Topic removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadTopic();
      }
    },
    setAnswers(ind) {
      this.options.forEach((el, index) => {
        if (index == ind) el.isCorrect = true;
        else el.isCorrect = false;
      });
    },
    AddValue() {
      // console.log("add val");
      this.quesData.push({
        question: {
          id: 0,
          campusID: this.$store.state.userData.cId,
          question: "",
          media: "",
          addExp: false,
          explanation: "",
          explanationMedia: "",
        },
        options: [
          {
            id: 0,
            option: "",
            isCorrect: false,
            questionID: 0,
            campusID: this.$store.state.userData.cId,
          },
          {
            id: 0,
            option: "",
            isCorrect: false,
            questionID: 0,
            campusID: this.$store.state.userData.cId,
          },
        ],
      });
      this.$nextTick(() => {
        let elem = this.$refs["ques"];
        // elem[this.quesData.length - 1].updateVisible(true);
        // console.log(elem[this.quesData.length - 1]);
      });
    },
    async removeValue(item, ind) {
      // console.log(item, ind);
      this.quesData.splice(ind, 1);
      // if (item.question.id == 0){
      //   this.quesData.splice(ind, 1);
      // }
      // else {
      //   var status = await this.delete({
      //     url:
      //       this.$store.state.domain +
      //       "QuizQuestions/removeQuestion" +
      //       item.id +
      //       "?db=" +
      //       this.$store.state.userData.db,
      //     body: null,
      //     grading: true,
      //     context: this,
      //     token: this.$store.state.userData.token,
      //   });
      //   if (status)  this.quesData.splice(ind, 1);
      //   // console.log(this.quesData);
      // }
    },
    addOption(item) {
      item.options.push({
        id: 0,
        option: "",
        isCorrect: false,
        questionID: item.question.id,
        campusID: this.$store.state.userData.cId,
      });
    },
    removeOption(item, ind) {
      // console.log(item, ind);
      item.splice(ind, 1);
      // if (item[ind].id == 0){
      //  item.splice(ind, 1);
      // }
      // else {
      //   var status = await this.delete({
      //     url:
      //       this.$store.state.domain +
      //       "QuizQuestions/removeQuestion" +
      //       item.id +
      //       "?db=" +
      //       this.$store.state.userData.db,
      //     body: null,
      //     grading: true,
      //     context: this,
      //     token: this.$store.state.userData.token,
      //   });
      //   if (status) item.splice(ind, 1);
      // }
    },

    checkValue() {
      let state = false;
      this.options.forEach((el) => {
        if (el.isCorrect) state = true;
      });
      return state;
    },
    checkLevel() {
      var elem = this.$refs["diff"];
      if (this.myObj.catIDs.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkTypeID() {
      var elem = this.$refs["ques_type"];
      if (this.myObj.questionTypeID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkClsID() {
      var elem = this.$refs["class"];
      if (this.myObj.classID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkSubID() {
      var elem = this.$refs["subject"];
      if (this.myObj.subjectID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkChID() {
      var elem = this.$refs["ques_chp"];
      if (this.myObj.chapterID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkTopID() {
      var elem = this.$refs["topic"];
      if (this.myObj.topicID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async save() {
      this.checkLevel();
      this.checkTypeID();
      this.checkClsID();
      this.checkSubID();
      this.checkChID();
      this.checkTopID();
      if (
        this.checkLevel() == true &&
        this.checkTypeID() == true &&
        this.checkClsID() == true &&
        this.checkSubID() == true &&
        this.checkChID() == true &&
        this.checkTopID() == true
      ) {
        if (this.checkValue() == true) {
          this.myObj.options = this.options;
          console.log(this.myObj);

          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "QuizQuestions/SaveQuestion?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: this.myObj,
            message: "Quiz Question added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.$router.push("/manage-quiz");
          }
        } else {
          this.$bvToast.toast("Please select an answer for your question.", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        }
      } else {
        this.$bvToast.toast("Please enter the details correctly!", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    deleteCover() {
      this.myObj.question.media = null;
      this.coverLoading = false;
      this.$refs.coverImg.value = null;
    },
    selectCover() {
      // https://cdn.cloudious.net/file-1683207466927-684850391.png
      if (this.$refs.coverImg.files[0] !== "") {
        this.coverLoading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.coverImg.files[0]);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.myObj.question.media = fn1;
            this.coverLoading = "loaded";
          })
          .catch((err) => {
            console.log(err);
            this.coverLoading = false;
          });
      }
    },
    deleteExpl() {
      this.myObj.question.explanationMedia = null;
      this.expLoading = false;
      this.$refs.expImg.value = null;
    },
    selectExpl() {
      if (this.$refs.expImg.files[0] !== "") {
        this.expLoading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.expImg.files[0]);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.myObj.question.explanationMedia = fn1;
            this.expLoading = "loaded";
          })
          .catch((err) => {
            console.log(err);
            this.expLoading = false;
          });
      }
    },

    async LoadData() {
      this.dataLoading = true;
      var obj2 = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj2);
      result.forEach((el) => this.classes.push(el.cls));

      var obj = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.subjects = await this.get(obj);

      this.dataLoading = false;
      // console.log("subject", this.items);
    },

    async loadQues() {
      var obj = {
        url:
          this.$store.state.domain +
          "QuizQuestions/LoadQuestion?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&qID=" +
          this.$route.params.id,
        token: this.$store.state.userData.token,
      };

      this.myObj = await this.get(obj);
      if (this.myObj.question.media) this.coverLoading = "loaded";
      else this.coverLoading = false;
      if (this.myObj.question.explanationMedia) this.expLoading = "loaded";
      else this.expLoading = false;
      this.options = this.myObj.options;
    },

    async loadCat() {
      var obj = {
        url:
          this.$store.state.domain +
          "QuizCategory?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.categories = await this.get(obj);

      // console.log("categories", this.categories);
    },
    async loadTopic() {
      var obj = {
        url:
          this.$store.state.domain +
          "QuizTopic?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.topics = await this.get(obj);

      // console.log("topics", this.topics);
    },

    async loadChaps() {
      var obj = {
        url:
          this.$store.state.domain +
          "QuizChapters?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.chapters = await this.get(obj);

      // console.log("chapters", this.chapters);
    },

    openChap() {
      this.chapObj = {
        id: 0,
        chapter: "",
        subjectID: this.myObj.subjectID,
        campusID: this.$store.state.userData.cId,
      };
      this.visibility3 = true;
      this.buttonIcon = "PlusIcon";

      var elem = this.$refs["chapter"];
      elem.state = undefined;
      var elem = this.$refs["ch_sub"];
      elem.state = undefined;

      if (this.chapObj.subjectID > 0) {
        setTimeout(() => {
          let e = document.getElementById("chp");
          e.focus();
        }, 200);
      }
    },

    editChap(item) {
      this.chapObj = { ...item };
      this.buttonIcon = "Edit2Icon";

      var elem = this.$refs["chapter"];
      elem.state = undefined;
      var elem = this.$refs["ch_sub"];
      elem.state = undefined;
    },

    checkChap() {
      var elem = this.$refs["chapter"];
      if (this.chapObj.chapter.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckChSub() {
      var elem = this.$refs["ch_sub"];
      if (this.chapObj.subjectID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async AddChap() {
      this.checkChap();
      this.CheckChSub();
      if (this.checkChap() == true && this.CheckChSub() == true) {
        if (this.chapObj.id == 0) {
          //Add
          this.chapLoading = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "QuizChapters?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: this.chapObj,
            message: "Chapter added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.chapLoading = false;
          if (status) {
            this.loadChaps();
            this.chapObj = {
              id: 0,
              chapter: this.chapObj.chapter,
              subjectID: this.chapObj.subjectID,
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        } else {
          //Edit
          this.chapLoading = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "QuizChapters/" +
              this.chapObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.chapObj,
            message: "Chapter updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.chapLoading = false;
          if (status) {
            this.loadChaps();
            this.chapObj = {
              id: 0,
              chapter: "",
              subjectID: 0,
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        }
      }
    },

    async deleteChap(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "QuizChapters/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Chapter removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadChaps();
      }
    },

    async loadTypes() {
      var obj = {
        url:
          this.$store.state.domain +
          "QuizQuestionType?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.quesTypes = await this.get(obj);

      // console.log("quesTypes", this.quesTypes);
    },

    openType() {
      this.typeObj = {
        id: 0,
        questionType: "",
        campusID: this.$store.state.userData.cId,
      };

      this.visibility4 = true;
      this.buttonIcon = "PlusIcon";

      var elem = this.$refs["q_type"];
      elem.state = undefined;
    },

    editType(item) {
      this.typeObj = { ...item };
      this.buttonIcon = "Edit2Icon";

      var elem = this.$refs["q_type"];
      elem.state = undefined;
    },

    checkType() {
      var elem = this.$refs["q_type"];
      if (this.typeObj.questionType.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    async AddType() {
      if (this.checkType() == true) {
        if (this.typeObj.id == 0) {
          //Add
          this.typeLoading = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "QuizQuestionType?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: this.typeObj,
            message: "Quiz Type added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.typeLoading = false;
          if (status) {
            this.loadTypes();
            this.typeObj = {
              id: 0,
              questionType: "",
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        } else {
          //Edit
          this.typeLoading = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "QuizQuestionType/" +
              this.typeObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.typeObj,
            message: "Quiz Type updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.typeLoading = false;
          if (status) {
            this.loadTypes();
            this.typeObj = {
              id: 0,
              questionType: "",
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        }
      }
    },

    async deleteType(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "QuizQuestionType/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Quiz Type removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadTypes();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
